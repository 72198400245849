import { getErrorMessage } from "@/utils/error";
import { ApiErrorCode, ApiErrorResponse } from "@common/api/api-types";
import { toast } from "@givenwell/components";
import { CancelPurchaseBody, CreatePurchaseBody } from "@givenwell/marketplace-api";
import {
  infiniteQueryOptions,
  queryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { getUserId, useMeQuery } from "./auth";
import { myWalletQueryOptions } from "./me";
import { api, queryClient } from "./utils";

export const infinitePurchaseHistoryQueryOptions = (userId: string, supplierId?: string) =>
  infiniteQueryOptions({
    initialPageParam: 1,
    queryKey: ["purchases", "list", { userId, supplierId }],
    queryFn: async ({ pageParam }) =>
      (await api.purchases.getPurchaseHistory({ memberId: userId, page: pageParam, pageSize: 10, supplierId }))
        .purchases,
    enabled: !!userId,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < 10) {
        return undefined;
      }

      return pages.length + 1;
    },
  });
export function useInfinitePurchaseHistoryQuery(userId: string) {
  return useInfiniteQuery(infinitePurchaseHistoryQueryOptions(userId));
}
useInfinitePurchaseHistoryQuery.prefetch = async () => {
  await useMeQuery.prefetch();
  const userId = getUserId();

  await queryClient.prefetchInfiniteQuery(infinitePurchaseHistoryQueryOptions(userId));
};

export const purchaseQuery = (purchaseId: string) =>
  queryOptions({
    queryKey: ["purchases", "detail", purchaseId],
    queryFn: () => api.purchases.getPurchaseForMember({ purchaseId }),
    enabled: !!purchaseId,
    placeholderData: prev => prev,
  });
export function usePurchaseQuery(purchaseId: string | undefined) {
  return useQuery(purchaseQuery(purchaseId!));
}

// -------------------------------------------------------------------------------------------------

export function useCreatePurchaseMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: CreatePurchaseBody) => {
      return await api.purchases.createPurchase({ requestBody: args });
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["me", "wallet"] });
      client.removeQueries({ queryKey: ["listing-availability"] });
      client.removeQueries({ queryKey: ["purchases", "list"] });
      client.removeQueries({ queryKey: ["subscriptions"] });
    },
    onError(e) {
      const { title, description } = describePurchaseApiError(e as unknown as ApiErrorResponse);
      toast.error(title, {
        description,
      });
    },
  });
}

function describePurchaseApiError(error: ApiErrorResponse): { title: string; description: string } {
  switch (error.body.code) {
    case ApiErrorCode.TimeSlotNotAvailable:
      return {
        title: "Sorry",
        description: "The time slot you selected is no longer available. Please choose another.",
      };
    default:
      return {
        title: "Purchase failed",
        description: getErrorMessage(error),
      };
  }
}

// -------------------------------------------------------------------------------------------------
// Cancel purchase
// -------------------------------------------------------------------------------------------------

export const cancellationInformationForPurchaseQuery = (purchaseId: string) =>
  queryOptions({
    queryKey: ["purchases", "cancellation-info", purchaseId],
    queryFn: () => api.purchases.getCancellationInformationForPurchase({ purchaseId }),
  });

export function useCancelPurchaseMutation(purchaseId: string) {
  return useMutation({
    mutationFn: async (requestBody: CancelPurchaseBody) => {
      return await api.purchases.cancelPurchase({ purchaseId, requestBody });
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: myWalletQueryOptions.queryKey, exact: true });
      queryClient.invalidateQueries({
        queryKey: infinitePurchaseHistoryQueryOptions(getUserId()).queryKey,
        exact: true,
      });
      queryClient.invalidateQueries({ queryKey: purchaseQuery(purchaseId).queryKey, exact: true });
    },
  });
}
