import {
  ApiError,
  UpdateFavouriteListingRequest,
  UpdateFavouriteResourceRequest,
  UpdateMyAddressRequest,
  UpdateMyLocationRequest,
  UpdateProfileRequest,
} from "@givenwell/marketplace-api";
import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

export const myExpirationsQuery = queryOptions({
  queryKey: ["my-expirations"],
  queryFn: async () => {
    const response = await api.me.getMyExpirations();
    return response.expirations;
  },
});

export const favouriteListingIdsQuery = queryOptions({
  queryKey: ["favourite-listing-ids"],
  queryFn: async () => {
    const favourites = await api.me.getFavouriteListingIDs();
    return new Set(favourites.listing_ids);
  },
});

export function useFavouriteListingIdsQuery() {
  return useQuery(favouriteListingIdsQuery);
}

export const favouriteResourceIdsQuery = queryOptions({
  queryKey: ["favourite-resource-ids"],
  queryFn: async () => {
    const response = await api.me.getFavouriteResourceIds();
    return new Set(response.resource_ids);
  },
});

export function useFavouriteResourceIdsQuery() {
  return useQuery(favouriteResourceIdsQuery);
}

export const favouritesQuery = queryOptions({
  queryKey: ["favourites"],
  queryFn: async () => {
    const response = await api.me.getFavourites();
    return response;
  },
});

export function useFavouritesQuery() {
  return useQuery(favouritesQuery);
}

export function useMyAddressQuery() {
  return useQuery({
    queryKey: ["me", "address"],
    queryFn: async () => {
      return await api.me.getMyAddress();
    },
  });
}

export const myWalletQueryOptions = queryOptions({
  // Reload wallet every 30 secs.
  // Don't refetch if the user doesn't have a valid wallet, e.g. admin users.
  refetchInterval: query => {
    const error = query.state.error as ApiError;
    if (error && error.status === 400) {
      return false;
    }
    return 1000 * 30;
  },
  queryKey: ["me", "wallet"],
  queryFn: async () => {
    const response = await api.me.getMyWallet();
    return response.wallets?.[0] || null;
  },
});
export function useMyWalletQuery() {
  return useQuery(myWalletQueryOptions);
}
useMyWalletQuery.prefetch = () => queryClient.prefetchQuery(myWalletQueryOptions);

export const myLocationQueryOptions = queryOptions({
  queryKey: ["me", "location"],
  queryFn: async () => {
    const response = await api.me.getMyLocation();
    return response.location || null;
  },
});
export function useMyLocationQuery() {
  return useQuery(myLocationQueryOptions);
}
useMyLocationQuery.prefetch = () => queryClient.prefetchQuery(myLocationQueryOptions);

export const mySupportersQuery = () =>
  queryOptions({
    queryKey: ["my-supporters"],
    queryFn: async () => {
      return await api.me.getMySupporters();
    },
  });

// -------------------------------------------------------------------------------------------------

export function useUpdateFavouriteListingMutation() {
  return useMutation({
    mutationFn: async (args: UpdateFavouriteListingRequest) => {
      await api.me.updateFavouriteListing({ requestBody: args });
    },
    onMutate(variables) {
      queryClient.setQueryData(favouriteListingIdsQuery.queryKey, oldData => {
        if (!oldData) return oldData;
        const newSet = new Set(oldData);
        if (variables.is_favourite) {
          newSet.add(variables.listing_id);
        } else {
          newSet.delete(variables.listing_id);
        }
        return newSet;
      });
    },
    onSuccess() {
      queryClient.invalidateQueries(favouriteListingIdsQuery);
      queryClient.invalidateQueries(favouritesQuery);
    },
    onError(_error, variables) {
      queryClient.setQueryData(favouriteListingIdsQuery.queryKey, oldData => {
        if (!oldData) return oldData;
        const newSet = new Set(oldData);
        if (variables.is_favourite) {
          newSet.delete(variables.listing_id);
        } else {
          newSet.add(variables.listing_id);
        }
        return newSet;
      });
    },
  });
}

export function useUpdateFavouriteResourceMutation() {
  return useMutation({
    mutationFn: async (args: UpdateFavouriteResourceRequest) => {
      await api.me.updateFavouriteResource({ requestBody: args });
    },
    onMutate(variables) {
      queryClient.setQueryData(favouriteResourceIdsQuery.queryKey, oldData => {
        if (!oldData) return oldData;
        const newSet = new Set(oldData);
        if (variables.is_favourite) {
          newSet.add(variables.resource_id);
        } else {
          newSet.delete(variables.resource_id);
        }
        return newSet;
      });
    },
    onSuccess() {
      queryClient.invalidateQueries(favouriteResourceIdsQuery);
      queryClient.invalidateQueries(favouritesQuery);
    },
    onError(_error, variables) {
      queryClient.setQueryData(favouriteResourceIdsQuery.queryKey, oldData => {
        if (!oldData) return oldData;
        const newSet = new Set(oldData);
        if (variables.is_favourite) {
          newSet.delete(variables.resource_id);
        } else {
          newSet.add(variables.resource_id);
        }
        return newSet;
      });
    },
  });
}

export function useUpdateProfileMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: UpdateProfileRequest) => {
      await api.me.updateMyProfile({ requestBody: args });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["me"],
      });
    },
  });
}

export function useUpdateMyAddressMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateMyAddressRequest) => {
      return await api.me.updateMyAddress({ requestBody: body });
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["me", "address"] });
    },
  });
}

export function useUpdateMyLocationMutation() {
  return useMutation({
    mutationFn: async (req: UpdateMyLocationRequest) => {
      await api.me.updateMyLocation({ requestBody: req });
    },
    onSettled() {
      queryClient.resetQueries({
        queryKey: ["marketplace-home"],
      });
      queryClient.invalidateQueries();
    },
  });
}
