import { mySupportersQuery } from "@/api/me";
import { queryClient } from "@/api/utils";
import { Outlet, createRoute, notFound } from "@tanstack/react-router";
import { appRoute } from "../+route";

export const resourcesRoute = createRoute({
  path: "resources",
  getParentRoute: () => appRoute,
  component: Outlet,
  beforeLoad: async () => {
    const supporterData = await queryClient.ensureQueryData(mySupportersQuery());
    const supporters = supporterData?.supporters ?? [];
    console.log({ supporters });

    if (!supporters.length) {
      throw notFound();
    }
  },
});
