export function dollarsToCents(dollars: number, currency = "NZD") {
  const decimalPlaces = getCurrencyDecimalPlaces(currency);
  return Math.round(dollars * 10 ** decimalPlaces);
}

export function centsToDollars(cents: number, currency = "NZD") {
  const decimalPlaces = getCurrencyDecimalPlaces(currency);
  return cents / 10 ** decimalPlaces;
}

/**
 * Get the size of the "minor unit" for a currency relative to the base unit.
 */
export function getCurrencyDecimalPlaces(currencyCode: string) {
  switch (currencyCode) {
    // Burundi Franc
    case "BIF":
    // Chilean Peso
    case "CLP":
    // Djibouti Franc
    case "DJF":
    // Guinean Franc
    case "GNF":
    // Iceland Krona
    case "ISK":
    // Yen
    case "JPY":
    // Comorian Franc
    case "KMF":
    // Won
    case "KRW":
    // Guarani
    case "PYG":
    // Rwanda Franc
    case "RWF":
    // Uganda Shilling
    case "UGX":
    // Uruguay Peso en Unidades Indexadas (UI)
    case "UYI":
    // Dong
    case "VND":
    // Vatu
    case "VUV":
    // CFA Franc BEAC
    case "XAF":
    // CFA Franc BCEAO
    case "XOF":
    // CFP Franc
    case "XPF":
      return 0;
    // Bahraini Dinar
    case "BHD":
    // Iraqi Dinar
    case "IQD":
    // Jordanian Dinar
    case "JOD":
    // Kuwaiti Dinar
    case "KWD":
    // Libyan Dinar
    case "LYD":
    // Rial Omani
    case "OMR":
    // Tunisian Dinar
    case "TND":
      return 3;
    // Unidad de Fomento
    case "CLF":
    // Unidad Previsional
    case "UYW":
      return 4;
    default:
      return 2;
  }
}
