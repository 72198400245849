import { useInfiniteTransactionsQuery } from "@/api/transactions";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { walletRoute } from "./+route";

export const walletIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => walletRoute,
  loader: async () => {
    useInfiniteTransactionsQuery.prefetch();
  },

  component: lazyRouteComponent(() => import("./+index.lazy")),
});
