import { createGlobalState, useGlobalState } from "@givenwell/hooks";

function getNotificationPermission() {
  if (!("Notification" in window)) return "default";
  return Notification.permission;
}

export const serviceWorkerRegistrationState = createGlobalState<ServiceWorkerRegistration | undefined>(undefined);
const notificationPermissionState = createGlobalState<NotificationPermission>(getNotificationPermission());
export function handlePermissionChange() {
  notificationPermissionState.setValue(getNotificationPermission());
}

const ONE_HOUR_IN_MS = 1000 * 60 * 60;

export async function registerServiceWorker() {
  if (!("serviceWorker" in navigator)) return;

  try {
    const registration = await navigator.serviceWorker.register("/service-worker.js");

    // Check for updates to the service worker every hour.
    setInterval(() => {
      registration.update().catch(e => console.error("Failed to update service worker", e));
    }, ONE_HOUR_IN_MS);

    serviceWorkerRegistrationState.setValue(registration);
  } catch (e) {
    console.error("Failed to register service worker", e);
  }
  handlePermissionChange();
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
}

export async function subscribeToPush() {
  const registration = serviceWorkerRegistrationState.getValue();
  console.log(registration);
  if (!registration) return;

  const sub = await registration.pushManager.getSubscription();
  if (sub) {
    // already registered, but theres no harm in re-registering
  }

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY),
  };

  const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);

  handlePermissionChange();
  return pushSubscription;
}

export async function unsubscribeFromPush() {
  const registration = serviceWorkerRegistrationState.getValue();
  if (!registration) return;

  const sub = await registration.pushManager.getSubscription();
  if (!sub) return;

  const res = await sub.unsubscribe();

  handlePermissionChange();

  return res;
}

export function useNotificationPermission() {
  return useGlobalState(notificationPermissionState)[0];
}
