import { locale } from "@common/utils/i18n";

const wholeNumberFormatter = new Intl.NumberFormat(locale, {
  maximumFractionDigits: 0,
});

export function formatWholeNumber(n: number | undefined): string {
  if (n === undefined) return "";

  return wholeNumberFormatter.format(n);
}

/** Returns the ordinal suffix for a number. */
export function ordinalSuffix(n: number): string {
  const lastTwoDigits = n % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return "th";

  switch (n % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes <= 0) return "0b";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${units[i]}`;
}
