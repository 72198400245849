import { useSearchRadiusStore } from "@/pages/(app)/marketplace/useSearchRadius";
import { seededShuffle } from "@givenwell/components/src/utils/math";
import { GetHomeControllerListingsResponseGroup } from "@givenwell/marketplace-api";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const makeHomeQueryOptions = (searchRadius: number, includeDrafts = false) =>
  queryOptions({
    queryKey: ["marketplace-home", { searchRadius, includeDrafts }],
    queryFn: async () => {
      const results = await api.home.getMarketplaceHome({ searchRadiusMeters: searchRadius, includeDrafts });
      const shuffledGroups: GetHomeControllerListingsResponseGroup[] = [];
      for (const group of results.groups || []) {
        if (!group.listings.length) continue;

        group.listings = seededShuffle(group.listings);
        shuffledGroups.push(group);
      }
      return shuffledGroups;
    },
  });

export function useMarketplaceHomeQuery(includeDrafts = false) {
  const searchRadius = useSearchRadiusStore(state => state.searchRadius);
  return useQuery(makeHomeQueryOptions(searchRadius, includeDrafts));
}
useMarketplaceHomeQuery.prefetch = () => queryClient.prefetchQuery(makeHomeQueryOptions(40000));
