import { api } from "@/api/utils";
import { createRoute, lazyRouteComponent, notFound } from "@tanstack/react-router";
import { topUpRoute } from "../+route";

export const checkoutRoute = createRoute({
  path: "checkout/$tokenAmount",
  getParentRoute: () => topUpRoute,
  loader: async ({ params }) => {
    const tokenAmount = Number(params.tokenAmount);
    if (!isFinite(tokenAmount)) {
      throw notFound();
    }
    const integerTokenAmount = Math.floor(tokenAmount);
    if (tokenAmount !== integerTokenAmount) {
      throw notFound();
    }
    if (integerTokenAmount < 1) {
      throw notFound();
    }
    const quoteResponse = await api.topup.quoteTokenCosts({ tokenAmount: [integerTokenAmount] });
    const quote = quoteResponse.quotes[0];
    if (!quote) {
      throw notFound();
    }
    const topupPurchase = await api.topup.startTopupPurchase({ requestBody: { token_amount: integerTokenAmount } });

    return { quote, topupPurchase };
  },
  // Do not cache this route's data after it's unloaded
  gcTime: 0,
  // Only reload the route when the user navigates to it or when deps change
  shouldReload: false,
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
